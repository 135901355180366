const WillscotLogo = require("../../logos/willscot-logo.png");
import SunsetLogo from "../../logos/sunset-logo.svg";
import RaptorLogo from "../../logos/Velociti-Logo.webp";
import styles from "./AppHeader.module.scss";

export default function AppHeader() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const customer = urlParams.get("customer");
  return (
    <div className={styles.headerStyleDark}>
      <img
        style={{
          height: "55px",
        }}
        src={
          customer === "willscot"
            ? WillscotLogo
            : customer === "sunset"
            ? SunsetLogo
            : customer === "raptor"
            ? RaptorLogo
            : ""
        }
      />
    </div>
  );
}
