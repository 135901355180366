import React, { useContext, useRef } from "react";
import ThemeContext from "../../theme/ThemeContext";
import { Route, Routes } from "react-router-dom";
import "../../scss/style.scss";
import AppFooter from "../appFooter";
import AppHeader from "../appHeader";
import "./AppStyles.scss";
import "@coreui/coreui-pro/dist/css/coreui.min.css";

const Vehicle = React.lazy(() => import("../routes/vehicle/Render"));

function App() {
  const main = useRef(null);

  return (
    <div className={"main-style-dark o2n-app"}>
      <AppHeader />
      <React.Suspense>
        <div className="body">
          <main
            data-testid="main"
            ref={main}
            style={{
              display: "flex",
              flexGrow: 1,
              minHeight: "100%",
            }}
          >
            <div className="container-fluid px-sm-0">
              <Routes>
                <Route path="/" element={<Vehicle />} />
              </Routes>
            </div>
          </main>
        </div>
      </React.Suspense>
      <div>
        <AppFooter />
      </div>
    </div>
  );
}

export default App;
