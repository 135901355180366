import { useContext, useEffect, useState } from "react";
const SPLogoLightmode = require("../../logos/SPLogoLightmode.png");
const SPLogoDarkmode = require("../../logos/SPLogoDarkmode.png");
import StealthIntellLogoDark from "../../logos/StealthIntellLogo-White.svg";
import "./AppFooterStyles.scss";

export default function AppFooter() {
  return (
    <footer className={"footer-dark"}>
      <div>
        {"Powered by"}
        <img
          style={{
            marginLeft: "10px",
            height: "40px",
          }}
          src={StealthIntellLogoDark}
        />
      </div>
    </footer>
  );
}
