import { combineReducers } from "redux";
import metricsReducer from "./metricsReducer";
import snapshotVehiclesReducer from "./snapshotVehicleReducer";
import snapshotMetricsReducer from "./snapshotMetricsReducer";
import faultCodesReducer from "./faultCodesReducer";
import multiVehiclesReducer from "./multiVehicleReducer";

const rootReducer = combineReducers({
  metrics: metricsReducer,
  snapshotVehicle: snapshotVehiclesReducer,
  snapshotMetrics: snapshotMetricsReducer,
  faultCodes: faultCodesReducer,
  multiVehicles: multiVehiclesReducer,
});

export default rootReducer;

//export type State = ReturnType<typeof rootReducer>;
