import {
  MultiVehicleDispatchTypes,
  MULTI_VEHICLES_LOADING,
  MULTI_VEHICLES_FAILURE,
  MULTI_VEHICLES_SUCCESS,
  MultiVehicles,
} from "../actions/multiVehicleActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  vehicles?: Array<MultiVehicles>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const multiVehiclesReducer = (
  state: DefaultStateInterface = defaultState,
  action: MultiVehicleDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case MULTI_VEHICLES_FAILURE:
      return {
        loading: false,
      };
    case MULTI_VEHICLES_LOADING:
      return {
        loading: true,
      };
    case MULTI_VEHICLES_SUCCESS:
      return {
        loading: false,
        vehicles: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type MultiVehicleRootState = ReturnType<typeof multiVehiclesReducer>;

export default multiVehiclesReducer;
