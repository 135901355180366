import { ReactElement, ReactNode } from "react";
export const MULTI_VEHICLES_LOADING = "MULTI_VEHICLES_LOADING";
export const MULTI_VEHICLES_FAILURE = "MULTI_VEHICLES_FAILURE";
export const MULTI_VEHICLES_SUCCESS = "MULTI_VEHICLES_SUCCESS";

export type MultiVehicles = {
  id: string;
  AssetNumber: string;
  MCUFirmwareVersion: string;
  DCMFirmwareVersion: string;
  LatestMCUFirmwareVersion: string;
  LatestDCMFirmwareVersion: string;
  Date: string;
  DeviceName: string;
  StealthBatteryAmps: number;
  StealthBatteryVolts: number;
  SolarAmps: number;
  ShorelineVolts: number;
  SystemTemperature: number;
  StateOfCharge: number;
  OEMBatteryVoltage: number;
  FuelLevel: number;
  BMSMaxCellVolts: number;
  RSSI: number;
  BMSPackVolts: number;
  SystemState: number;
  CustomerGroup: string;
  UnitType: string;
  DaylightSignal: boolean;
  ActiveLoad: boolean;
  SolarVolts: number;
};

export interface MultiVehicleLoading {
  type: typeof MULTI_VEHICLES_LOADING;
}

export interface MultiVehicleFailure {
  type: typeof MULTI_VEHICLES_FAILURE;
}

export interface MultiVehicleSuccess {
  type: typeof MULTI_VEHICLES_SUCCESS;
  payload: Array<MultiVehicles>;
}

export type MultiVehicleDispatchTypes =
  | MultiVehicleLoading
  | MultiVehicleFailure
  | MultiVehicleSuccess;
